const SITE_A = true;

const DEV_MODE = false;

// if DEV_MODE == true, set LOCAL_IP for mobile proxy
const LOCAL_IP = false;//'http://192.168.1.100/api/';

//
//
//

let env;

if (DEV_MODE) {

    //let api = 'http://localhost/api/'; SITE A
    //let api = 'http://localhost:9013/api/'; SITE B
    let api = 'http://localhost/api/';

    if (LOCAL_IP != false) {
        api = LOCAL_IP;
    }
    
    env = {
        api,
        uploadsFolder: 'http://localhost/api/uploads/',
        siteA: SITE_A,
    };

} else {
    env = {
        api: '/api/',
        uploadsFolder: '/api/uploads/',
        siteA: SITE_A,
    };
}

export default env
